// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/Article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-articles-js": () => import("./../src/templates/Articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-homepage-js": () => import("./../src/templates/Homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/Tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

